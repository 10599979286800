





















































































import Vue from 'vue'
import { mapState } from 'vuex'
import { ApiResponse, Product } from '@/interfaces'


// Interface après transformation
interface ProductOption {
  id: number | null | undefined;
  name: string | null | undefined;
}

interface ProductGroup {
  category: string;
  options: ProductOption[];
}

export default Vue.extend({
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      busy: false,
      viewportWidth: 0,
      chosenProduct: null,
      products: [] as ProductGroup[],
    }
  },
  computed: {
    ...mapState(['user']),
  },
  mounted() {
    this.loadProducts()
    
    this.viewportWidth = window.innerWidth

    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.viewportWidth = window.innerWidth
    },
    closeModal(updateCheckboxValue = true) {
      this.chosenProduct = null

      this.$emit('closeFeeModal', updateCheckboxValue)
    },
    submit() {
      this.busy = true
      this.$api
        .put(`/requisitionitems/fee/add`, {
          items: this.items,
          data: this.chosenProduct,
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          const countItems = apiResponse.data.count
          this.$emit('updateItems', apiResponse.data.items)
          this.$notify({
            type: 'success',
            title: 'Succès',
            message: `Le frais a été appliqué sur ${countItems} lot(s). Vous pouvez ajoutez d'autres frais ou fermer la fenêtre`,
          })
        })
        .finally(() => {
          this.busy = false
          this.closeModal(false)
        })
    },
    loadProducts() {
      this.products = []

      const items = this.items.join(',')

      this.$api
        .get('/products', {
          params: {
            items: items,
            type: 'requisition',
          },
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.products = this.groupProductsByCategory(apiResponse.data);

        })
    },
    groupProductsByCategory(products: Product[]): ProductGroup[] {
      const grouped: { [key: string]: ProductOption[] } = {};

      // Parcours des produits pour les regrouper par catégorie
      products.forEach((item) => {
        const categoryKey = item.category ?? "Divers"; // Utiliser "Divers" si category est null

        // Si la catégorie n'existe pas, la créer
        if (!grouped[categoryKey]) {
          grouped[categoryKey] = [];
        }

        // Ajouter le produit à la catégorie correspondante
        grouped[categoryKey].push({ id: item.id, name: this.getLabel(item) });
      });

      // Retourner les groupes sous la forme de ProductGroup[]
      return Object.keys(grouped).map((category) => ({
        category,
        options: grouped[category],
      }));
    },

    getLabel(product: Product) {
      return (
        product.name +
        ' - P.U ' +
        product.unitPriceExcludingTaxes +
        ' - ' +
        product.tvaRate +
        '% - ' +
        product.structure?.name
      )
    },
  },
})
