








































































































































import Vue from 'vue'
import { mapState } from 'vuex'
import { Fee, ItemFile, Project, Requisition, RequisitionItem, Sale } from '@/interfaces'
import { stockStatuses } from '@/formHelpers'
import HighlightIcon from '@/components/Icons/HighlightIcon.vue'
import SignedIcon from '@/components/Icons/SignedIcon.vue'

interface Rubrique {
  children?: Rubrique[]
  label: string
  value: string
}

interface Badge {
  color: string
  value: string
}

interface DropdownAction {
  action: string
  item?: RequisitionItem
  index?: number
  renumberBy?: string
}

export default Vue.extend({
  components: { HighlightIcon, SignedIcon },
  props: {
    item: {
      type: Object as () => RequisitionItem,
      required: false,
      default: null,
    },
    project: {
      type: Object as () => Project,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    page: {
      type: String,
      required: false,
      default: '',
    },
    itemsLength: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      stockStatuses,
      rubriques: [],
      selectedItems: [] as RequisitionItem[],
      lastCheckedIndex: -1,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    item: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.$set(this.item, 'isChecked', newVal.isChecked)
      },
    },
    user(newVal) {
      this.rubriques = newVal.client.config.rubriques
    },
  },
  mounted() {
    if (this.user) {
      this.rubriques = this.user.client.config.rubriques
    }
  },
  methods: {
    toggleChecked(item: RequisitionItem, index: number, event: Event | null) {
      this.$emit('toggleCheck', item, index, event)
    },
    editRequisitionItem(item: RequisitionItem) {
      this.$router.push({
        name: 'requisition_item_edit',
        params: {
          itemId: item.id?.toString() ?? '',
        },
        query: {
          backTo: this.$route.name,
          backScrollTo: window.scrollY.toString(),
        },
      })
    },
    getSaleTitle(sale: Sale): string {
      let saleTitle = ''
      if (sale?.title) {
        saleTitle += ' ' + sale?.title
      }
      if (sale?.startDate) {
        const date = Date.parse(sale?.startDate)
        if (date) {
          saleTitle += ' - ' + new Intl.DateTimeFormat('fr-FR').format(date)
        }
      }
      return saleTitle !== '' ? saleTitle : '-'
    },
    getRubriqueRec(rubriques: Rubrique[], str: string): string {
      let rub = '-'
      rubriques.forEach((rubrique: Rubrique) => {
        if (rubrique.value == str) {
          rub = rubrique.label
        }
        if (rubrique.children && rub == '-') {
          rub = this.getRubriqueRec(rubrique.children, str)
        }
      })

      return rub
    },
    getEndPrice(item: RequisitionItem): string {
      return item.endPrice ? `${item.endPrice} €` : '-'
    },
    getReservePrice(item: RequisitionItem): string {
      let response
      if(item.reservePrice != null){
        response = `${item.reservePrice} / `
      }else{
        response = `- / `
      }
      if(item.reserveNetPrice != null){
        response += `${item.reserveNetPrice} €`
      }else{
        response += `- €`
      }        
      return response
    },
    getEstimationPrice(item: RequisitionItem): string {
      let estimationPrice = ''

      if (item.estimationLow !== null) {
        estimationPrice = `${item.estimationLow} €`
      }

      if (item.estimationHigh !== null) {
        estimationPrice = estimationPrice + ` - ${item.estimationHigh} €`
      }

      return estimationPrice
    },
    getFeeBadge(fee: Fee): Badge {
      const badge: Badge = {
        color: '',
        value: fee?.label ?? '-',
      }
      switch (fee.type) {
        case 'percent_buyer':
        case 'fixe_buyer':
          badge.color = '#CE60AC26'
          break

        case 'percent_seller':
        case 'fixe_seller':
        case 'frais':
        case 'vente':
          badge.color = '#4365D626'
          break

        case 'emolument':
        case 'autre':
        default:
          badge.color = '#4C4C8326'
          break
      }
      return badge
    },
    getFeeBadgeColor(fee: Fee) {
      let color

      switch (fee.type) {
        case 'percent_buyer':
        case 'fixe_buyer':
          color = '#CE60AC'
          break

        case 'percent_seller':
        case 'fixe_seller':
        case 'frais':
          color = '#4365D6'
          break

        case 'autre':
        case 'emolument':
        default:
          color = '#4C4C83'
          break
      }

      return {
        color,
        'border-color': color,
      }
    },
    getMediaList(item: RequisitionItem) {
      return item.galleryImages ?? []
    },
    cleanRequisitionItemFiles(files: ItemFile[]) {
      return files.filter(this.cleanDeletedFiles).sort(this.sortByPosition)
    },
    cleanDeletedFiles(file: ItemFile) {
      return file.deletedAt !== 'NULL'
    },
    sortByPosition(file1: ItemFile, file2: ItemFile) {
      if (file1.position > file2.position) {
        return 1
      }

      if (file1.position < file2.position) {
        return -1
      }

      return 0
    },
    getThumbnail(files: ItemFile[], type: string) {
      for (let index = 0; index < files.length; index++) {
        const element = files[index]
        if (element.type == type && element.deletedAt == null) {
          return element.urlThumb
        }
      }

      return null
    },
    getProjectInfo(requisition: Requisition) {
      let projectInfo = ''
      if (requisition.project?.generatedNumber) {
        projectInfo = `${requisition.project.generatedNumber}`
      }
      if (requisition.debiteur) {
        projectInfo = `${projectInfo} \n> ${requisition.debiteur.formName}`
      }

      return projectInfo
    },
    handleItemCommand(command: DropdownAction) {
      if (command.action == 'document') {
        this.$emit('document', command.index ?? null)
      }

      // Edit item
      if (command.action == 'edit' && command.item) {
        this.editRequisitionItem(command.item)
      }

      // Duplicate item
      if (command.action == 'duplicate' && command.item) {
        this.$emit('duplicate', command.item)
      }

      // Delete item
      if (command.action == 'delete' && command.item) {
        this.$emit('delete', command.item)
      }

      // Detach from sale
      if (command.action == 'detach' && command.item) {
        this.$emit('detachItemFromSale', command.item)
      }

      // Express edit for prices
      if (command.action == 'editPrices' && command.item) {
        this.$emit('editPrices', command.item)
      }

      // Regrouper des lots sur ce lot
      if (command.action == 'regroup' && command.item) {
        this.$emit('regroup', command.item)
      }
    },
    docIsSigned(item: RequisitionItem) {
      return item.salesMandate?.signed ?? null
    },
    getStructureColor(item: RequisitionItem) {
      return item.requisition?.project?.structure?.color
    },
  },
})
